<template>
	<template-base class="containerCard">
		<div>
			<div class="container-head">
				<div
					v-if="possuiPermissao('GER_I_USUARIOS')"
					class="container-add-new"
					v-bind:class="!users.length ? 'container-add-new-100' : ''"
				>
					<b-button class="add-button" @click="mostrarEdicao({})">
						Adicionar
					</b-button>
				</div>
			</div>
			<div v-if="users.length" class="head-row mb-2 width-pagination-items">
				<div class="text-head-row width-pagination">
					<label class="text-head-row ml-0">
						Resultado {{ pagination.offset + 1 }}-{{ pagination.offset + users.length }} de {{ pagination.count }}
					</label>
					<b-pagination
						class="mr-0 mb-2"
						id="page"
						v-model="currentPage"
						:total-rows="pagination.count"
						:per-page="pagination.limit.value"
					></b-pagination>
				</div>
				<b-form
					class="text-head-row width-pagination">
					<label class="text-head-row ml-0">
						Itens por página
					</label>
					<b-form-select
						class="mr-0 mb-2"
						v-model="pagination.limit.value"
						name="itens-por-pagina"
						:options="optionsItensPorPag"
						@input="changeItensByPage()"
						:disabled="loading"
					/>
				</b-form>
			</div>
			<b-modal
				ref="modalEdicao"
				hide-footer
				:title="titleModalEdicao"
				size="lg"
			>
				<div class="modal-edicao mb-5 modal-edicao-label">
					<b-row class="mt-3">
						<b-col class="text-parcela">
							<span class="label-conta">Nome Completo</span>
							<b-form-input
								name="nome"
								class="modal-edicao-input"
								type="text"
								v-model="usuarioSelecionado.name"
								autocomplete="off"
								placeholder="Digite o seu nome"
								v-validate="{ required: true }"
								:state="validateState('nome')"
								@input="validateForm()"
							/>
						</b-col>
						<b-col
							v-if="!hideSubscription"
							cols="3" class="text-parcela">
							<div class="toggle-div">
								<span class="label-conta">Assinatura</span>
								<ToggleSwitch
									name="subscription"
									class="toggle"
									:value="usuarioSelecionado.subscription"
									@toggle="_ => changeToggle(usuarioSelecionado.subscription)"
								/>
							</div>
						</b-col>
					</b-row>
					<b-row class="mt-3 filters-orientation">
						<b-col class="text-parcela">
							<span class="label-conta">E-mail</span>
							<b-form-input
								name="email"
								class="modal-edicao-input"
								type="text"
								v-model="usuarioSelecionado.email"
								autocomplete="off"
								placeholder="Digite o seu e-mail"
								v-validate="{ required: false }"
								:state="validateState('email')"
								@input="validateForm()"
							/>
						</b-col>
						<b-col class="text-parcela">
							<span class="label-conta">Grupo de Usuário</span>
							<b-form-select
								name="id-grupo-usuario"
								class="modal-edicao-input mr-1 mb-2"
								v-model="usuarioSelecionado.idGroupUser"
								v-validate="{ required: true }"
								:state="validateState('id-grupo-usuario')"
								:options="gruposUsuarios"
								:disabled="loading"
								@input="validateForm()"
							>
								<template slot="first">
									<option :value="null">
										Nenhum
									</option>
								</template>
							</b-form-select>
						</b-col>
					</b-row>
					<b-row class="mt-3 filters-orientation">
						<b-col class="text-parcela">
							<span class="label-conta">Usuário</span>
							<b-form-input
								name="usuario"
								class="modal-edicao-input"
								type="text"
								v-model="usuarioSelecionado.user"
								autocomplete="off"
								placeholder="Digite um nome de usuário"
								v-validate="{ required: true }"
								:state="validateState('usuario')"
								@input="validateForm()"
							/>
						</b-col>
						<b-col class="text-parcela">
							<span class="label-conta">Senha</span>
							<b-form>
								<InputSenha
									id="senha"
									name="senha"
									v-model="usuarioSelecionado.password"
									@input="validateForm()"
									:validate="noEditUser"
									:placeholder="!noEditUser ? 'Inalterada' : 'Digite a senha'"
								/>
							</b-form>
						</b-col>
					</b-row>
				</div>
				<b-button class="ml-1 mt-5 float-left btn-modal btn-cancel" @click="fecharModal('modalEdicao')">Cancelar</b-button>
				<b-button class="mt-5 float-left btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
				<b-button
					class="mt-5 mr-1 float-right btn-modal btn-salvar"
					@click="salvarEdicao"
					:disabled="invalidForm"
				>
					Salvar
				</b-button>
			</b-modal>
		</div>
		<div v-if="users.length" class="table-responsive">
			<DataTable v-if="!isMobile"
				class="data-table"
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:nosearch="true"
				:nofilters="true"
				name="usuarios"
				:hasPagination="true"
				:noedit="true"
				:acoes="true"
				:permissaoEdit="possuiPermissao('GER_U_USUARIOS')"
				:permissaoDelete="possuiPermissao('GER_D_USUARIOS')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
				@clickSortTableBy="(v) => sortTableBy(v)"
				:state="{
					sortBy: sortTable.sortBy,
					sortAsc: sortTable.order === 1,
					query: ''
				}"
				:async="true"
			/>
			<CardTransaction v-else
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:acoes="true"
				:permissaoEdit="possuiPermissao('GER_U_USUARIOS')"
				:permissaoDelete="possuiPermissao('GER_D_USUARIOS')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
				:selectItem="false"
				:globalAcoes="false"
				@clickCheck="false"
				:colsDataCard="colsDataCard"
				:details="false"
				:showSelectItem="false"
			/>
		</div>
		<div v-else class="alert alert-warning" role="alert">
			{{ errMsg }}
		</div>
	</template-base>
</template>

<script>
	import { createHash } from "crypto";

	import DataTable from "@/components/DataTable";
	import InputSenha from "@/components/InputSenha";
	import TemplateBase from "@/templates/Base";

	import { possuiPermissao } from "../../helpers/permissions";
	import { UsersService } from "../../services/users";
	import { GroupsUsersService } from "../../services/groupsUsers";
	import { hasFieldsWithErrors } from "@/helpers/validators";
	import ToggleSwitch from "@/components/ToggleSwitch";
	import { formatterSimple } from "@/helpers/common";
	import CardTransaction from "@/components/CardTransaction.vue";

	import dayjs from "dayjs";
	// import "dayjs/locale/pt-br";
	// import { ptBR } from "vuejs-datepicker/dist/locale";

	export default {
		components: {
			TemplateBase,
			InputSenha,
			DataTable,
			ToggleSwitch,
			CardTransaction
		},

		inject: ["parentValidator"],

		data () {
			return {
				users: [],
				linhas: [],
				titleModalEdicao: "",
				loading: true,
				gruposUsuarios: [],
				usuarioSelecionado: {
					idUser: "",
					user: "",
					name: "",
					email: "",
					password: "",
					idGroupUser: null,
					subscription: false
				},

				usersService: new UsersService(),
				groupsUsersService: new GroupsUsersService(),

				errMsg: "Nenhum usuário cadastrado!",
				colunas: [
					"Nome Completo",
					"Usuário",
					"E-mail",
					"Grupo de Usuário",
					"Assinatura",
					"Último Login",
					"Última Conexão"
				],
				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Items"
					},
					{
						value: 25,
						text: "25 Items"
					},
					{
						value: 50,
						text: "50 Items"
					},
					{
						value: 75,
						text: "75 Items"
					},
					{
						value: 100,
						text: "100 Items"
					}
				],
				invalidForm: true,
				noEditUser: false,
				possuiPermissao,
				sortTable: {
					sortBy: 0,
					order: -1
				},
				hideSubscription: false,
				isMobile: false,
				colsDataCard: {
					0: {
						idx: 0,
						label: "Nome Completo",
						textAlign: "left",
						cols: 8,
						visible: true
					},
					1: {
						idx: 3,
						label: "Grupo",
						textAlign: "center",
						cols: 4,
						visible: true
					},
					2: {
						idx: 1,
						label: "Usuário",
						textAlign: "left",
						cols: 4,
						visible: true
					},
					3: {
						idx: 2,
						label: "E-mail",
						textAlign: "left",
						cols: 6,
						visible: true
					},
					4: {
						idx: 4,
						label: "Assinatura",
						textAlign: "center",
						cols: 2,
						visible: true
					},
					5: {
						idx: null,
						label: "",
						textAlign: "",
						cols: 0,
						visible: false
					},
					6: {
						idx: 5,
						label: "Último Login",
						textAlign: "center",
						cols: 6,
						visible: true
					},
					7: {
						idx: 6,
						label: "Última Conexão",
						textAlign: "center",
						cols: 6,
						visible: true
					}
				}
			};
		},

		watch: {
			currentPage () {
				this.changePage(this.currentPage);
			}
		},

		created () {
			this.$validator = this.parentValidator;
		},

		async mounted () {
			this.checkIfMobile();
			window.addEventListener("resize", this.checkIfMobile);

			this.gruposUsuarios = await this.optionsGruposUsuarios();
			this.findUsers();
		},

		beforeDestroy () {
			window.removeEventListener("resize", this.checkIfMobile);
		},

		methods: {
			checkIfMobile	() {
				this.isMobile = window.innerWidth <= 768;
			},

			sortTableBy (v) {
				this.sortTable = v;
				this.findUsers();
			},

			mostrar () {
				if (this.$refs.senha.type === "text")
					this.$refs.senha.type = "password";
				else
					this.$refs.senha.type = "text";
			},

			async optionsGruposUsuarios () {
				const gruposUsuarios = (await this.groupsUsersService.listGroupsUsers({ offset: null, limit: null })).rows || [];
				return gruposUsuarios.reduce((acc, gu) => [
					...acc,
					{
						value: gu.idGroupUser,
						text: gu.name
					}
				], []);
			},

			changePage () {
				this.pagination.page = this.currentPage;
				this.findUsers();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findUsers();
			},

			async findUsers () {
				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;
				const sortTable = this.sortTable;
				const result = await this.usersService.listUsers({ offset, limit, sortTable }) || {};
				this.users = result.rows || [];

				this.linhas = result.rows.reduce((acc, user) => [
					...acc,
					{
						idUser: user.idUser,
						cols: [
							user.name,
							user.user,
							user.email,
							(this.gruposUsuarios.filter(gu => gu.value === user.idGroupUser))[0].text || "",
							user.subscriptionRequired ? "Sim" : "Não",
							user.lastLogin ? formatterSimple(user.lastLogin, true) : "-",
							user.lastConnection ? formatterSimple(user.lastConnection, true) : "-"
						]
					}
				], []);
				this.pagination.count = result.count || 0;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
			},

			mostrarEdicao (item_) {
				this.invalidForm = true;
				let item = {};
				if (item_.idUser)
					[ item ] = this.users.filter((user) => user.idUser === item_.idUser);

				const { idUser, name, user, email, idGroupUser, subscriptionRequired } = item;

				if (!idUser) {
					this.titleModalEdicao = "Cadastrar Novo Usuário";
					this.noEditUser = true;
				} else {
					this.titleModalEdicao = "Editar Usuário";
					this.noEditUser = false;
				}

				this.usuarioSelecionado.idUser = idUser || "";
				this.usuarioSelecionado.user = user || "";
				this.usuarioSelecionado.name = name || "";
				this.usuarioSelecionado.email = email || "";
				this.usuarioSelecionado.idGroupUser = idGroupUser || null;
				this.usuarioSelecionado.subscription = subscriptionRequired || false;
				this.$refs.modalEdicao.show();
			},

			async confirmaApagar (item_) {
				const [ item ] = this.users.filter((user) => user.idUser === item_.idUser);
				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja remover o usuário?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item.idUser);
			},

			async salvarEdicao () {
				const invalidFields = await this.validateModal();
				if (invalidFields) {
					await this.$swal({
						title: "Formulário Inválido",
						text: "Existe informação sem preencher!",
						type: "error",
						showCancelButton: false,
						confirmButtonColor: "#DD6B55",
						//imageWidth: 50,
						//imageHeight: 50,
						confirmButtonText: "OK",
						reverseButtons: true
					});
					return;
				}

				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				const payload = {
					idUser: this.usuarioSelecionado.idUser,
					name: this.usuarioSelecionado.name,
					email: this.usuarioSelecionado.email,
					user: this.usuarioSelecionado.user,
					password: this.usuarioSelecionado.password ? createHash("sha512").update(this.usuarioSelecionado.password).digest("hex") : "",
					idGroupUser: this.usuarioSelecionado.idGroupUser,
					subscriptionRequired: this.usuarioSelecionado.subscription
				};

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						await this.usersService.upsertUser({payload});

						this.fecharModal("modalEdicao");
						this.findUsers();

						return {
							title: "Sucesso!",
							body: "Usuário salvo com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar salvar usuário!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			fecharModal (modal) {
				this.$refs[modal].hide();
				this.limparModal();
				this.usuarioSelecionado.idUser = "";
			},

			async apagar (idUser) {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						await this.usersService.deleteUser({ idUser });
						this.findUsers();

						return {
							title: "Sucesso!",
							body: "Usuário deletado com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar deletar o usuário!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			limparModal () {
				this.usuarioSelecionado.name = "";
				this.usuarioSelecionado.email = "";
				this.usuarioSelecionado.user = "";
				this.usuarioSelecionado.password = "";
				this.usuarioSelecionado.idGroupUser = null;
				this.usuarioSelecionado.subscription = false;
			},

			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			},

			async validateModal () {
				const validationFields = Object.keys(this.veeFields);
				if (!validationFields.length)
					return true;

				const validations = validationFields.reduce((acc, ref) => {
					if (this.veeFields[ref] && this.veeFields[ref].invalid)
						return [...acc, this.veeFields[ref].invalid];

					return acc;
				}, []);

				const invalidFields = validations.filter(field => field);
				return Boolean(invalidFields && invalidFields.length);
			},

			async validateForm () {
				const formFields = Object.keys(this.usuarioSelecionado);
				const invalidFields = formFields.reduce((acc, ff) => {
					if (ff === "email" || ff === "idUser" || this.usuarioSelecionado[ff] || (!this.noEditUser && ff === "password"))
						return acc;

					return [ ...acc, ff ];
				}, []);
				this.invalidForm = Boolean(invalidFields.length);
			},

			changeToggle (status) {
				this.usuarioSelecionado.subscription = !status;
			}
		}
	};
</script>

<style scoped>
	.table-th-funcionalidade {
		font-family: "Roboto Condensed Medium";
		letter-spacing: -0.05em;
		font-size: 20px;
	}

	.table-td-funcionalidade {
		font-family: "Roboto Condensed Regular";
		letter-spacing: -0.05em;
		font-size: 16px;
	}

	label {
		width: 160px;
	}

	.container-head {
		display: flex;
		height: 5rem;
		width: 100%;
	}

	.container-pages {
		display: flex;
		width: 70%;
		margin-top: 1.5rem;
	}

	.containerCard {
		max-width: 90.5%;
	}

	.modal-edicao-label {
		font-family: "Roboto Condensed Regular";
		font-size: 23px;
		letter-spacing: -0.05em;
	}

	.modal-edicao-input {
		font-family: "Roboto Condensed Regular";
		font-size: 20px;
		letter-spacing: -0.05em;
	}

	.table-body-detalhe {
		padding: 5px 0;
	}

	.container-add-new-100 {
		width: 100% !important;
	}

	.toggle {
		margin-top: 7px;
	}

	.toggle-div {
		width: 100%;
		text-align: center;
	}
</style>

<style>
	.form-control {
		height: auto !important;
	}
</style>
